import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors, mobileThresholdPixels, FormInput, fontSizes } from './v3/styledComponents';
import { ErrorText, Button } from './v2/StyledComponents';
import OutInSideClick from './v2/OutInSideClick';
import checkError, { getDeliveryFees } from '../../services/zipcode';

const OpacityContainer = styled.div`
  background: ${colors.opacityFilter};
  overflow: auto;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1050;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  overflow: visible;
  width: 530px;
  margin: 0 auto;
  padding: 60px 50px;

  background-color: ${colors.lightGrey};
  color: ${colors.navy};
  font-family: Roboto;
  font-size: 18px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 90vw;
    padding: 22px 12px;
    margin-top: 100px;
    margin-bottom: 25px;
  }
`;

const Title = styled.div`
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 26px;
  @media (max-width: ${mobileThresholdPixels}) {
    text-align: center;
    margin-bottom: 22px;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${mobileThresholdPixels}) {
    width: 80%;
  }
`;

const Span = styled.span`
  color: ${colors.lightGrey4};
  ${({ withLessMarginTop }) => withLessMarginTop && 'margin-top: -1em;'}
  @media (max-width: ${mobileThresholdPixels}) {
    text-align: center;
    font-size: ${fontSizes.s};
  }
`;

const ResponseText = styled.div`
  margin-top: 26px;
  text-align: center;
`;

const FeeSpan = styled.span`
  color: ${colors.navy};
  font-weight: bold;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
  }
`;

const StyledFormInput = styled(FormInput)`
  width: 360px;
  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 26px;
`;

class PopupDeliveryFees extends Component {
  constructor() {
    super();
    this.state = { zipcode: undefined, errorMessage: '' };
    this.submitZipcode = this.submitZipcode.bind(this);
  }

  submitZipcode() {
    const { zipcodeInput } = this.state;
    this.setState({ zipcode: zipcodeInput, errorMessage: checkError(zipcodeInput) });
  }

  render() {
    const { closePopup } = this.props;
    const { zipcode, errorMessage } = this.state;
    const deliveryFees = getDeliveryFees(zipcode);
    return (
      <OpacityContainer>
        <OutInSideClick handleClickOutside={closePopup}>
          <Container>
            <Title>Quel est le code postal de votre client ?</Title>
            <FormContainer>
              <StyledFormInput
                type={'text'}
                full
                placeholder={'Entrez le code postal'}
                error={errorMessage}
                onChange={e => this.setState({ zipcodeInput: e.target.value })}
              />
              {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
              {deliveryFees && (
                <ResponseText>
                  <Span>{"Nos Tillistes peuvent venir jusqu'à chez vous ;)"}</Span><br />
                  <Span withLessMarginTop>Les frais de déplacement sont de <FeeSpan>{deliveryFees} €</FeeSpan></Span>
                </ResponseText>
              )}
              <ButtonContainer>
                <Button navy onClick={this.submitZipcode}>Valider</Button>
              </ButtonContainer>
            </FormContainer>
          </Container>
        </OutInSideClick>
      </OpacityContainer>
    );
  }
}

PopupDeliveryFees.propTypes = {
  closePopup: PropTypes.func.isRequired,
};

export default PopupDeliveryFees;
