import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import routesMap from '../../Routes';
import { trackEvent } from '../../services/analytics';
import isMobile from '../../services/isMobile';
import { colors, margins, mobileThresholdPixels, fontSizes, ContainerFooter, Content, Column }
  from '../../components/home/v2/StyledComponents';
import phone from '../../config/contactPhone';
import logoTilli from '../../assets/logos/tilli.white.svg';

const footerNotes = [
  { index: 0, text: 'Notre assurance axa', url: routesMap.Insurance.url, trackingId: 'notre-assurance-axa' },
  {
    index: 1,
    text: 'FAQ',
    url: routesMap.FAQPage.url,
    trackingId: 'faq',
  },
  // { index: x, text: 'Nos tarifs', url: routesMap.HomePage.url, trackingId: 'nos-tarifs' },
  { index: 2, text: 'CGV/CGU', href: '/CGU_CGV_Tilli_13_05_18.pdf', trackingId: 'CGV-CGU' },
  // { index: x, text: 'Parrainez un ami', url: routesMap.HomePage.url, trackingId: 'parrainez-un-ami' },
  { index: 3, text: 'Tous droits reservés', url: routesMap.LegalTerms.url, trackingId: 'tous-droits-reservés' },
];

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  color: ${colors.white};
`;

const StyledContent = styled(Content)`
  @media (max-width: ${mobileThresholdPixels}) {
    width: auto;
    padding: 0px ${margins.s};
  }
`;

const LogoImg = styled.img`
  width: 35%;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 85%;
    margin-top: ${margins.m};
  }
`;

const FooterNotesContainer = styled.div`
  margin: ${margins.l} 0px;
  font-size: ${fontSizes.s};
  text-align: center;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.l} 0px ${margins.m} 0px;
  }
`;

const SubFooterNoteContainer = styled.span`
  @media (max-width: ${mobileThresholdPixels}) {
    display: flex;
    flex-direction: column;
    line-height: 26px;
  }
`;

const TextContainer = styled.span`
  text-decoration: none;
  font-family: Roboto;
  font-size: ${fontSizes.m};
  color: white;
  ${props => props.pointer && 'cursor: pointer'};
  ${props => props.lineHeight && `line-height: ${props.lineHeight}`};
`;

const A = styled.a`
  color: ${colors.navy};
  text-decoration: none;
`;

const Footer3 = () => (
  <ContainerFooter>
    <StyledContent navy>
      <Column justifyContent={'space-between'} textAlign={'center'} alignItems={'center'} alignContent={'center'}>
        <SubContainer>
          <LogoImg src={logoTilli} alt={'logo Tilli'} />
          <FooterNotesContainer>
            {footerNotes.map(content =>
              <SubFooterNoteContainer key={content.index}>
                {content.url ?
                  <Link
                    style={{ textDecoration: 'none' }}
                    to={content.url}
                    onClick={() => trackEvent('click', `${content.trackingId}_block-footer3`)}
                  >
                    <TextContainer pointer>{content.text}</TextContainer>
                  </Link>
                  :
                  <A
                    href={content.href}
                    onClick={() => trackEvent('click', `${content.trackingId}_block-footer3`)}
                  >
                    <TextContainer pointer>{content.text}</TextContainer>
                  </A>
                }
                {(content.index !== (footerNotes.length - 1) && !isMobile()) &&
                  <span>{' | '}</span>
                }
              </SubFooterNoteContainer>,
            )}
            <br />
            <TextContainer lineHeight={'36px'}>
              Nous contacter : contact@tilli.fr ou {phone}
            </TextContainer>
          </FooterNotesContainer>
        </SubContainer>
      </Column>
    </StyledContent>
  </ContainerFooter>
);

export default Footer3;
