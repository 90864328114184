/* eslint-disable max-len */
const laRubriquePosts = require('./json/laRubriquePosts.json');
const laRubriqueCategories = require('./json/laRubriqueCategories.json');
const interviews = require('./json/interviews.json');
const landings = require('./json/landings.json');

// ************************************************
// Penser à mettre à jour sitemap.xml et robots.txt
// ************************************************
const routes = {
  HowItWorksPage: {
    url: '/comment-fonctionne-tilli-le-service-de-retoucherie-a-paris-et-marseille/',
    component: 'src/pagesComponents/home/HowItWorksPage.js',
    seo: {
      title: 'Service de retouches à domicile : découvrez Tilli',
      description: 'Un service simple, de qualité et transparent : 1. La prise de RDV - 2. Le RDV avec votre Tilliste - 3. Le retour de vos vêtements',
    },
  },
  AboutPage: {
    url: '/a-propos-de-tilli/',
    component: 'src/pagesComponents/home/AboutPage.js',
    seo: {
      title: 'A propos : découvrez l‘histoire derrière Tilli',
      description: 'Chez Tilli, nous partageons tous un objectif commun : celui de remettre au goût du jour le métier de couturier en le digitalisant. Le couturier échange directement avec le client pour ne pas perdre en qualité et laisser place à une expérience unique !',
    },
  },
  StyleGuide: {
    url: '/styleguide/',
    component: 'src/pagesComponents/styleGuide/StyleGuide.js',
  },
  BeTillistePage: {
    url: '/devenir-couturier/',
    component: 'src/pagesComponents/home/BeTillistePage.js',
    seo: {
      title: 'Couturier - travailler à Marseille et Paris chez Tilli',
      description: 'Tout savoir sur le métier de couturier Tilli. Travaillez quand vous voulez, en toute simplicité. Nous recrutons à Paris, Marseille, Lyon, Nice, Bordeaux, etc',
    },
  },
  LegalTerms: {
    url: '/mentions-legales/',
    component: 'src/pagesComponents/home/LegalTerms.js',
    seo: {
      title: '📃 mentions légales 📃 - Tilli',
    },
  },
  Login: {
    url: '/se-connecter/',
    component: 'src/pagesComponents/myAccount/Login.js',
    seo: {
      title: 'Se connecter - Tilli',
      description: 'Se connecter à son compte Tilli. Vous pouvez aussi télécharger notre appli pour suivre vos commandes.',
    },
  },
  ForgottenPasswordRequest: {
    url: '/mot-de-passe-oublie/',
    component: 'src/pagesComponents/myAccount/ForgottenPasswordRequest.js',
    seo: {
      title: 'Mot de passe oublié - Tilli',
    },
  },
  ResetPassword: {
    url: '/nouveau-mot-de-passe/:token/',
    component: 'src/pagesComponents/myAccount/ResetPassword.js',
    seo: {
      title: 'Nouveau mot de passe - Tilli',
    },
  },
  MyOrders: {
    url: '/mes-commandes/',
    component: 'src/pagesComponents/myAccount/MyOrders.js',
    seo: {
      title: 'Mes commandes - Tilli',
    },
  },
  Insurance: {
    url: '/assurance-AXA-en-toute-serenite/',
    component: 'src/pagesComponents/home/Insurance.js',
    seo: {
      title: 'Tous vos vêtements assurés par AXA - Tilli',
      description: 'Toutes les commandes passées chez Tilli sont assurées par notre partenaire AXA. Tilli prend soin de ses clients et couturiers !',
    },
  },
  InsuranceTilliste: {
    url: '/assurance-AXA-tilliste/',
    component: 'src/pagesComponents/home/InsuranceTilliste.js',
    seo: {
      title: 'Tous nos tillistes sont couverts par AXA - Tilli',
      description: 'En tant que tilliste, travaillez sans crainte grâce à notre partenaire AXA. Toutes vos missions sont couvertes par AXA.',
    },
  },
  PressPage: {
    url: '/ils-parlent-de-tilli-presse/',
    component: 'src/pagesComponents/home/PressPage.js',
    seo: {
      title: 'Ils parlent de nous - Tilli',
      description: 'Découvrez les articles de journaux et de blogs parlant de Tilli. Venez lire ce qu‘ont écrit LCI, Gustave et Rosalie, Madame Figaro et d‘autres.',
    },
  },
  PartnersPage: {
    url: '/ils-nous-font-confiance-les-partenaires-tilli/',
    component: 'src/pagesComponents/home/PartnersPage.js',
    seo: {
      title: 'Nos partenaires - Tilli',
      description: 'Ils font confiance à Tilli pour leurs retouches : Madura, Sézane, Isabel Marant, Violette Sauvage et bien d‘autres',
    },
  },
  WhyBecomePartner: {
    url: '/tilli-votre-partenaire-retouche-domicile-boutique-ecommerce/',
    component: 'src/pagesComponents/home/WhyBecomePartner.js',
    seo: {
      title: 'Nous choisir comme partenaire retouches - Tilli',
      description: 'Vous êtes une marque ou une boutique, pourquoi choisir Tilli comme partenaire retouches ? Pour améliorer l‘expérience client, pour simplifier votre gestion, et plus…',
    },
  },
  EcosystemPage: {
    url: '/ecosysteme-tilli/',
    component: 'src/pagesComponents/home/EcosystemPage.js',
  },
  FAQPage: {
    url: '/les-questions-les-plus-frequentes-tilli-retouche/',
    component: 'src/pagesComponents/home/FAQPage.js',
    seo: {
      title: 'FAQ : les questions les plus fréquentes - Tilli',
      description: 'Retouches, délai de livraison, villes couvertes ? Toutes les réponses à vos question sur le service de couturier à domicile Tilli',
    },
  },
  PricingsPage: {
    url: '/nos-tarifs/',
    component: 'src/pagesComponents/home/PricingsPage.js',
    seo: {
      title: 'Nos tarifs - Tilli',
      description: 'Les tarifs de Tilli, le service de couturier à domicile au prix de la retoucherie de quartier. L’ourlet simple de pantalon à 12€./',
    },
  },
  OurArtisans: {
    url: '/nos-artisans/',
    seo: {
      title: 'Nos artisans couturiers : leurs paroles - Tilli',
      description: 'Nos Tillistes, artisans couturiers, se livrent sur leur métier et leur relation avec Tilli, notamment leurs meilleurs souvenirs de missions.',
    },
  },
  FeedbacksPage: {
    url: '/ils-ont-aime/',
    component: 'src/pagesComponents/home/FeedbacksPage.js',
    seo: {
      title: 'Les avis de nos clients - Tilli',
      description: 'Un service simple, de qualité et transparent : 1. La prise de RDV - 2. Le RDV avec votre Tilliste - 3. Le retour de vos vêtements',
    },
  },
  JobOfferPage: {
    url: '/rejoindre-aventure-tilli/',
    component: 'src/pagesComponents/home/JobOfferPage.js',
    seo: {
      title: 'Rejoindre l‘aventure Tilli : toutes nos offres de stage et d‘emploi',
      description: 'Tilli recrute ! Consultez toutes nos offres d‘emploi en CDI ainsi que nos offres de stage et postulez en ligne.',
    },
  },


  //
  // #######################
  // #  LA RUBRIQUE PAGES  #
  // #######################
  //
  LaRubrique: {
    url: '/la-rubrique/',
    seo: {
      title: 'La Rubrique Tilli : toutes les inspirations',
      description: 'Trouvez des inspirations pour votre dressing en fonction de vos habits et de vos matières : broderies, poches, transformations de vêtements…',
    },
  },


  //
  // #########################
  // #  ORDER PROCESS PAGES  #
  // #########################
  //
  Step1: {
    url: '/',
    component: 'src/pagesComponents/order/Step1Clothes.js',
    seo: {
      title: 'Réserver votre couturier à Paris, Marseille,… - Tilli',
      description: 'Réserver votre couturier à domicile en quelques clics pour toutes vos retouches de vêtements. Disponible à Marseille et Paris',
    },
  },

  Step1Summary: {
    url: '/order/step1-summary',
    component: 'src/pagesComponents/order/Step1Summary.js',
    seo: {
      title: 'Réserver votre couturier à Paris, Marseille,… - Tilli',
      description: 'Réserver votre couturier à domicile en quelques clics pour toutes vos retouches de vêtements. Disponible à Marseille et Paris',
    },
  },

  Step2: {
    url: '/order/step2',
    component: 'src/pagesComponents/order/Step2.js',
    seo: {
      title: 'Réserver votre couturier à Paris, Marseille,… - Tilli',
      description: 'Réserver votre couturier à domicile en quelques clics pour toutes vos retouches de vêtements. Disponible à Marseille et Paris',
    },
  },

  Step3: {
    url: '/order/step3',
    component: 'src/pagesComponents/order/Step3.js',
    seo: {
      title: 'Réserver votre couturier à Paris, Marseille,… - Tilli',
      description: 'Réserver votre couturier à domicile en quelques clics pour toutes vos retouches de vêtements. Disponible à Marseille et Paris',
    },
  },

  Step4: {
    url: '/order/step4',
    component: 'src/pagesComponents/order/Step4.js',
    seo: {
      title: 'Réserver votre couturier à Paris, Marseille,… - Tilli',
      description: 'Réserver votre couturier à domicile en quelques clics pour toutes vos retouches de vêtements. Disponible à Marseille et Paris',
    },
  },
};

laRubriquePosts.forEach((post) => {
  routes[`LaRubrique_${post.slug}`] = {
    url: post.path,
    seo: post.seo,
  };
});

laRubriqueCategories.forEach((category) => {
  routes[`LaRubriqueCategory_${category.slug}`] = {
    url: category.path,
    seo: category.seo,
  };
});

interviews.forEach((interview) => {
  routes[`OurArtisans_${interview.slug}`] = {
    url: interview.path,
    seo: interview.seo,
  };
});

landings.forEach((landing) => {
  routes[landing.slug] = {
    url: landing.path,
    seo: landing.seo,
  };
});

module.exports = routes;
