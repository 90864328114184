// cSpell:ignore mariee
import React from 'react';
import PropTypes from 'prop-types';
import { Background, Container } from '../../components/styledComponents';
import tilliBackgroundImage from '../../assets/headerV2.jpg';
import maduraBackgroundImage from '../../assets/brand/Madura.jpg';
import apcBackgroundImage from '../../assets/brand/APC.jpg';
import isabelMarantBackgroundImage from '../../assets/brand/Isabel-Marant-bg.jpg';
import carolinaRitzlerBackgroundImage from '../../assets/brand/CarolinaRitzler.jpg';
import sezaneBackgroundImage from '../../assets/brand/Sezane2.jpg';
import bourrienneBackgroundImage from '../../assets/brand/BourrienneParisX.jpg';
import theKooplesBackgroundImage from '../../assets/brand/TheKooples.jpg';
import degresBackgroundImage from '../../assets/brand/85degres.jpg';
import casablancaBackgroundImage from '../../assets/brand/Casablanca.jpg';
import galeriesLafayetteBackgroundImage from '../../assets/brand/GaleriesLafayetteBackground.jpg';
import sessunBackgroundImage from '../../assets/brand/Sessun.jpg';
import carollBackgroundImage from '../../assets/brand/Caroll.jpg';
import laMarieeCapricieuseBackgroundImage from '../../assets/brand/LaMarieeCapricieuse.jpg';
import sequoiaPressingBackgroundImage from '../../assets/brand/SequoiaPressingBackground.jpg';

function getBackgroundImage() {
  switch (process.env.GATSBY_BRAND) {
    case 'MADURA':
      return maduraBackgroundImage;
    case 'APC':
      return apcBackgroundImage;
    case 'ISABEL_MARANT':
      return isabelMarantBackgroundImage;
    case 'CAROLINA_RITZLER':
      return carolinaRitzlerBackgroundImage;
    case 'SEZANE':
      return sezaneBackgroundImage;
    case 'BOURRIENNE':
      return bourrienneBackgroundImage;
    case 'THE_KOOPLES':
      return theKooplesBackgroundImage;
    case '85_DEGRES':
      return degresBackgroundImage;
    case 'CASABLANCA':
      return casablancaBackgroundImage;
    case 'GALERIES_LAFAYETTE':
      return galeriesLafayetteBackgroundImage;
    case 'SESSUN':
      return sessunBackgroundImage;
    case 'CAROLL':
      return carollBackgroundImage;
    case 'LA_MARIEE_CAPRICIEUSE':
      return laMarieeCapricieuseBackgroundImage;
    case 'SEQUOIA_PRESSING':
      return sequoiaPressingBackgroundImage;
    default:
      return tilliBackgroundImage;
  }
}
const backgroundImage = getBackgroundImage();

const OrderContainer = ({ children }) => (
  <div>
    <Background backgroundImage={backgroundImage} />
    <Container>
      {children}
    </Container>
  </div>
);

OrderContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default OrderContainer;
