import React from 'react';
import styled from 'styled-components';

import MobileApps from './MobileApps';
import { colors, margins, mobileThresholdPixels, Title2Footer, ContainerFooter, Content, Column }
  from '../../components/home/v2/StyledComponents';

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  color: ${colors.navy};
`;

const StyledContent = styled(Content)`
  @media (max-width: ${mobileThresholdPixels}) {
    width: auto;
    padding: 0px ${margins.s};
  }
`;

const Footer2 = () => (
  <ContainerFooter white>
    <StyledContent>
      <Column justifyContent={'space-between'} textAlign={'center'} alignItems={'center'} alignContent={'center'}>
        <SubContainer>
          <Title2Footer>
            {'Télécharger l’appli Tilli'}
            <br />
            {'et simplifiez-vous l’habit.'}
          </Title2Footer>
          <MobileApps />
        </SubContainer>
      </Column>
    </StyledContent>
  </ContainerFooter>
);

export default Footer2;
