import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AuthShop from './AuthShop';
import callApi from '../services/api';
import featureSwitch from '../services/featureSwitch';
import withAuthContext from '../context/withAuthContext';

class AuthShopContainer extends Component {
  constructor() {
    super();
    this.state = {};
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    const brandId = featureSwitch('brandId');
    const { setShopName } = this.props.authContext;
    callApi(`brands/${brandId}/shops`).then(({ shops }) => {
      this.setState({ shops });
      if (shops[0] && shops[0].name) setShopName(shops[0].name);
    });
  }

  submit() {
    const { selectedShop: { _id: shopId, name }, password } = this.state;
    const { setAuth, setShopName } = this.props.authContext;
    this.setState({ error: false });
    callApi('/shops/login', 'POST', { shopId, password })
      .then(() => {
        setShopName(name);
        setAuth(true);
      })
      .catch(() => this.setState({ error: true }));
  }

  render() {
    const { shops, error } = this.state;
    return (
      <AuthShop
        shops={shops}
        setSelectedShop={selectedShop => this.setState({ selectedShop })}
        setPassword={password => this.setState({ password })}
        submit={this.submit}
        error={error}
      />
    );
  }
}

AuthShopContainer.propTypes = {
  authContext: PropTypes.shape({
    setAuth: PropTypes.func.isRequired,
    setShopName: PropTypes.func.isRequired,
  }).isRequired,
};

export default withAuthContext(AuthShopContainer);
