import React from 'react';
import AuthContext from './AuthContext';

export default function withAuthContext(Component) {
  return function WrapperComponent(props) {
    return (
      <AuthContext.Consumer>
        {state => <Component {...props} authContext={state} />}
      </AuthContext.Consumer>
    );
  };
}
