import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import isMobile from '../services/isMobile';
import seoConfig from '../seoConfig';
import routes from '../Routes';
import withAuthContext from '../context/withAuthContext';
import AuthShopContainer from '../components/AuthShopContainer';
import ContactForm from '../components/ContactForm';

import '../polyfill/trimStart';
import '../assets/flaticon/flaticon.css';

const disableScripts = () => typeof window !== 'undefined' && window.location.pathname === '/styleguide';

const disableScriptsExceptOnProd = () => !(process.env.GATSBY_ENV === 'PROD');

const getRoute = routeSlug => routes[routeSlug];

const getTitle = (routeSlug) => {
  if (!routeSlug) return seoConfig.title;
  const { seo = {} } = getRoute(routeSlug);
  return seo.title || seoConfig.title;
};

const getDescription = (routeSlug) => {
  if (!routeSlug) return seoConfig.description;
  const { seo = {} } = getRoute(routeSlug);
  return seo.description || seoConfig.description;
};

const getUrl = (routeSlug) => {
  if (!routeSlug) return seoConfig.baseUrl;
  const { url } = getRoute(routeSlug);
  return `${seoConfig.baseUrl}${url}`;
};

// TODO remove this temporary code to force to unregister SW
// SW was installed on users devices after a failed attempt to setup gatsby-offline-plugin
if (typeof navigator !== 'undefined' && navigator.serviceWorker && navigator.serviceWorker.getRegistrations) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    registrations.map(registration => registration.unregister());
  });
}

export const AppContext = createContext({
  isMobile: true,
});

class Layout extends React.Component {
  constructor() {
    super();
    this.state = { isMobile: true };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.setState({ isMobile: isMobile() });
  }

  render() {
    const { children, routeSlug, authContext: { isAuth } } = this.props;
    return (
      <div>
        <Helmet
          title={getTitle(routeSlug)}
          meta={[{ name: 'description', content: getDescription(routeSlug) }]}
          link={[{ rel: 'canonical', href: getUrl(routeSlug) }]}
        />

        {/* disable scripts for style guide */}
        {disableScripts() ?
          null :
          <Helmet>
            <html lang="fr" />
          </Helmet>}

        {disableScripts() ?
          null :
          <ContactForm />
        }

        {disableScriptsExceptOnProd() ?
          null :
          <Helmet>
            <script
              src="https://browser.sentry-cdn.com/4.6.5/bundle.min.js" async crossOrigin="anonymous"
              onLoad="Sentry.init({ dsn: 'https://4a6c40f9067f4f76a7cf96ffd5ea23a4@sentry.io/1425698' });"
            />
          </Helmet>
        }

        <AppContext.Provider value={this.state}>
          {isAuth ? children : <AuthShopContainer />}
        </AppContext.Provider>
      </div>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node,
  routeSlug: PropTypes.string,
  authContext: PropTypes.shape({
    isAuth: PropTypes.bool,
  }).isRequired,
};

Layout.defaultProps = {
  children: null,
  routeSlug: null,
};

export default withAuthContext(Layout);
